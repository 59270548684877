<template>
  <div v-if="loading" class="loader">
    <loader></loader>
  </div>
  <v-card v-else :dark="$dark.get()" fluid height="92vh">
    <v-card-title class="justify-space-between header">
      Зоны курьеров
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            id="add_area_button"
            fab
            v-bind="attrs"
            color="success lighten-1"
            small
            class="elevation-4"
            to="/couriers-area/new"
            v-on="on"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </template>
        <span>Добавить зону</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" style="padding-top: 2.5px">
          <yandex-map
            :zoom="map.zoom"
            :settings="mapSettings"
            :coords="map.coords"
            map-type="map"
            @boundschange="mapBoundschange"
            @map-was-initialized="initMap"
          >
          </yandex-map>
        </v-col>
        <v-col cols="12">
          <v-simple-table class="table">
            <thead>
              <tr>
                <th class="table_depart_head">ID</th>
                <th class="table_depart_head">Наименование района</th>
                <th class="table_depart_head">Действия</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in allZone" :key="item.id">
                <td>
                  {{ item.id }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  <v-btn :to="`/couriers-area/${item.id}`" color="primary"
                    >{{ "Посмотреть" }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
export default {
  components: { Loader },
  data() {
    return {
      myMap: {},
      allZone: [],
      polygonList: [],
      activeEditPolygonId: 0,
      editPolygonData: [],
      editPolygon: {},
      map: {
        zoom: 12,
        coords: [49.783936, 73.145232],
      },
      loading: false,
      mapSettings: {
        apiKey: "",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Couriers/STATE",
    }),
  },
  async created() {
    this.loading = true;
    await this.getAllDeliveryZone();
    this.allZone = this.STATE.allDeliveryZones;
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAllDeliveryZone: "Couriers/GET_ALL_DELIVERY_ZONES",
    }),
    mapBoundschange(e) {
      if (parseInt(e._cache.newZoom) > 0) {
        this.map.zoom = e._cache.newZoom;
      }
      if (typeof e._cache.newCenter.isArray === "undefined") {
        this.map.coords = e._cache.newCenter;
      }
    },
    initMap(map) {
      this.myMap = map;
      this.addPolygonList();
    },
    createPolygon() {
      // Создаем полигон для карты
      this.$set(this, "editPolygonData", {
        id: 0,
        coords: [],
        name: "",
        price: 0,
      });
      this.myMap.geoObjects.add(this.editPolygon);
    },
    addPolygonList() {
      // Создает из массива список полигонов на карте
      // Сначала удаляем все полигоны
      for (let i = 0; i < this.polygonList.length; i++) {
        this.myMap.geoObjects.remove(this.polygonList[i]);
      }

      if (typeof this.myMap["geoObjects"] == "undefined") return false;
      for (let i = 0; i < this.allZone.length; i++) {
        let coords = this.allZone[i].coords;
        if (typeof coords == "string")
          coords = JSON.parse(this.allZone[i].coords);
        if (coords) {
          this.polygonList[i] = new ymaps.Polygon(
            [coords],
            {
              hintContent: this.allZone[i].name,
            },
            {
              fillColor: "#00adff",
              interactivityModel: "default#transparent",
              strokeWidth: 1,
              opacity: 0.5,
            }
          );
          this.myMap.geoObjects.add(this.polygonList[i]);
          this.polygonList[i].id = this.allZone[i].id;

          this.polygonList[i].events.add("click", (e) => {
            this.activeEditPolygonId = e._cache.target.id;
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.ymap-container {
  @media screen and (max-width: 3000px) {
    min-height: 32vmax;
    height: 75%;
  }
  @media screen and (max-width: 1920px) {
    min-height: 530px;
    height: 80%;
  }
  @media screen and (max-width: 1440px) {
    min-height: 500px;
    height: 66%;
  }
  @media screen and (max-width: 1366px) {
    min-height: 380px;
    height: 45%;
  }
}
.ymap_btn {
  background-color: #fff;
  border-color: transparent;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15),
    0 2px 5px -3px rgba(0, 0, 0, 0.15);
  box-sizing: border-box !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  background-clip: border-box;
  color: #000;
  vertical-align: middle;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  -webkit-transition: background-color 0.15s ease-out,
    border-color 0.15s ease-out, opacity 0.15s ease-out;
  transition: background-color 0.15s ease-out, border-color 0.15s ease-out,
    opacity 0.15s ease-out;
  display: inline-block;
  text-align: left;
  height: 28px;
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  padding: {
    top: 4px;
    left: 10px;
    right: 10px;
  }

  &--blue {
    color: #fff;
    background-color: rgb(85, 201, 255);
  }
  &--red {
    color: #fff;
    background-color: #f55;
  }
  &--yellow {
    color: #fff;
    background-color: #f4c712;
  }
  &--green {
    color: #fff;
    background-color: #00c469;
  }
}
.loader {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}
</style>
